import request from '@/utils/request'


// 查询系统公告列表
export function listNotice(query) {
  return request({
    url: '/notice/notice/list',
    method: 'get',
    params: query
  })
}

// 查询系统公告分页
export function pageNotice(query) {
  return request({
    url: '/notice/notice/page',
    method: 'get',
    params: query
  })
}

// 查询系统公告详细
export function getNotice(data) {
  return request({
    url: '/notice/notice/detail',
    method: 'get',
    params: data
  })
}

// 新增系统公告
export function addNotice(data) {
  return request({
    url: '/notice/notice/add',
    method: 'post',
    data: data
  })
}

// 修改系统公告
export function updateNotice(data) {
  return request({
    url: '/notice/notice/edit',
    method: 'post',
    data: data
  })
}

// 删除系统公告
export function delNotice(data) {
  return request({
    url: '/notice/notice/delete',
    method: 'post',
    data: data
  })
}
